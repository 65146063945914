import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '0, 47, 108',
		'primary-dark': '1, 87, 155',
		'accent': '0, 186, 255',
		'accent-plus': '0, 0, 0',
	},
});
